const state = reactive({
    active: false,
    form: false,
});
export default () => {
    const active = computed(() => state.active);
    const form = computed(() => state.form);

    const setActive = (val: boolean) => {
        state.active = val;
    }
    const setForm = (val: boolean) => {
        state.form = val;
    }

    return {
        active,
        form,
        setActive,
        setForm
    }
}
import type {VueI18n} from "vue-i18n";
import type {Notification} from "~/integration/wordpress/types";

const state = reactive({
    notifications: [] as Notification[],
});
const maxVisibleNotifications = 2;
const timeToLive = 10000;

export default () => {
    const {i18n} = useNuxtApp();

    const getAllErrors = (errors: {message: string}) => {
        return Array.isArray(errors) ? errors.map((e) => e.message).join(',') : errors.toString() as string;
    }

    const sendMessage = (title: string, message: string, error = true) => {
        const id = title.replaceAll(' ', '_').toLowerCase();
        send({
            icon: error ? 'error' : 'check',
            id: Symbol(id),
            message: (i18n as VueI18n).t(message) as string,
            persist: false,
            title: (i18n as VueI18n).t(title) as string,
            type: error ? 'danger' : 'success',
        } as Notification);
    }

    const send = (notification: Notification) => {
        const id = Symbol('id');

        const dismiss = () => {
            const index = state.notifications.findIndex((n) => n.id === id);

            if (index !== -1) state.notifications.splice(index, 1);
        };

        const newNotification = {
            ...notification,
            id,
            dismiss,
        } as Notification;

        if (!state.notifications.some((stateNotification) => stateNotification.message === notification.message)) {
            state.notifications.push(newNotification);
        }

        if (state.notifications.length > maxVisibleNotifications) {
            state.notifications.shift();
        }

        if (!notification.persist) {
            setTimeout(dismiss, timeToLive);
        }
    };

    return {
        notifications: computed(() => state.notifications),
        isNotifications: computed(() => state.notifications.length > 0),
        sendMessage,
        getAllErrors,
    }
}